import {CountUp} from "countup.js";

function isFloat(value) {
	return !!(typeof value === 'number' &&
		!Number.isNaN(value) &&
		!Number.isInteger(value));
}

let tags = [
	["metricubi", 11.25],
	["giunti", 135],
	["strutture", 800],
	["altezza", 155],
	["ponte", 250],
	["anni", 50, {prefix: "+"}],
	["progetti", 4500, {prefix: "+"}],
	["soddisfazione", 100, {suffix: "%"}]
];


const options = {
	enableScrollSpy: true,
	scrollSpyDelay: 250,
	useGrouping: true,
	duration: 3,
	decimal: ",",
};

tags.forEach(element => {

	let customOptions = Object.assign({}, options)

	// Check if value it's float
	if (isFloat(element[1])) {
		customOptions.decimalPlaces = 2;
	}

	// Check if any custom options have been specified
	if (typeof element[2] === 'object' &&
		!Array.isArray(element[2]) &&
		element[2] !== null) {
		customOptions = Object.assign(customOptions, element[2])
	}

	let countUp = new CountUp(element[0], element[1], customOptions);

	if (!countUp.error) {
		countUp.start();
	}
});
